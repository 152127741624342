<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
      <div class="card-header">
        <h4>
          <i class="fa fa-file"></i>
          الاخبار (تظهر في التطبيق)
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 text-center">
          <img
            :src="image ? image : require('@/assets/images/upload.png')"
            style="
              width: 200px;
              height: 100px;
              cursor: pointer;
              object-fit: cover;
            "
          />
          <div id="progress-wrp">
            <div class="progress-bar"></div>
            <div class="status">0%</div>
          </div>
          <br />
          <input type="file" title="اضغط لتغيير الصورة" id="ingredient_file" />
        </div>
        <div class="form-group">
          <label for="">العنوان</label>
          <input type="text" class="form-control" v-model="title" />
        </div>
        <div class="form-group">
          <label for="">المحتوى</label>
          <textarea class="form-control" v-model="content" rows="3"></textarea>
        </div>

        <div class="g">
          <b-form-checkbox
            v-model="send_notis"
            name="check-button"
            class="custom-control-success"
            :value="true"
            switch
            inline
          >
            <h5>
              <i class="fa fa-paper-plane"></i>
              ارسال اشعار بالخبر
            </h5>
          </b-form-checkbox>
        </div>
      </div>
      <div class="col-12 text-center">
        <button class="btn btn-success" @click="save()">
          <i class="fa fa-save"></i>
          اضافة الخبر
        </button>
        <br /><br />
      </div>
    </div>
    <div class="card card-body">
      <div class="table-responsive col-12">
        <table class="table table-hover">
          <thead>
            <th>التاريخ</th>
            <th>العنوان</th>
            <th>المحتوى</th>
            <th>حذف</th>
          </thead>
          <tbody>
            <tr v-for="post in posts" :key="post._id">
              <td>
                {{ post.date }}
              </td>
              <td>
                <img
                  :src="post.image"
                  style="width: 50px; height: 30px"
                  alt="بدون صورة"
                />
                {{ post.title }}
              </td>
              <td>
                <textarea
                  class="form-control"
                  readonly
                  v-model="post.content"
                ></textarea>
              </td>
              <td>
                <button
                  class="btn btn-sm btn-danger fa fa-trash"
                  @click="deletePost(post._id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
export default {
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      posts: [],
      image: "",
      title: "",
      content: "",
      send_notis: true,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    if (this.user.admin_id) {
      alert("هذه الصفحة لحساب المدرسة الرئيسي فقط وليس للمشرفين");
      this.$router.push("/");
    }
    if (!checkPer("settings")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    $.post(api + "/user/general/news/list", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        if (r.status == 100) {
          g.posts = r.response;
        } else {
          alert(r.response);
        }
      })
      .catch(function () {
        alert("حدث خطا في الاتصال");
      });

    var Upload = function (file) {
      this.file = file;
    };

    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", this.file, this.getName());
      formData.append("jwt", g.user.jwt);
      $.ajax({
        type: "POST",
        url: api + "/user/general/upload",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          $("#progress-wrp").fadeOut("slow");
          g.image = data.response;
        },
        error: function (error) {
          // handle error
          alert("حدث خطأ");
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    };

    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    }, 1000);
  },
  methods: {
    deletePost(id) {
      if (confirm("متأكد من الحذف؟")) {
        var g = this;
        $.post(api + "/user/general/news/delete", {
          jwt: g.user.jwt,
          id: id,
        })
          .then(function (r) {
            if (r.status == 100) {
              alert("تم الحذف");
              location.reload();
            } else {
              alert(r.response);
            }
          })
          .catch(function () {
            alert("حدث خطا في الاتصال");
          });
      }
    },
    save() {
      var g = this;
      $.post(api + "/user/general/news/create", {
        jwt: g.user.jwt,
        title: g.title,
        image: g.image,
        content: g.content,
        send_notis: g.send_notis,
      })
        .then(function (r) {
          if (r.status == 100) {
            alert("تم الحفظ");
            location.reload();
          } else {
            alert(r.response);
          }
        })
        .catch(function () {
          alert("حدث خطا في الاتصال");
        });
    },
  },
};
</script>

<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>